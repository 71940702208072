<template>
  <v-app>
    <home-app-bar
      v-if="isMobile"
    />

    <home-view />

    <home-footer />

    <!-- <home-settings /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'HomeLayout',

    components: {
      HomeAppBar: () => import('@/layouts/home/AppBar'),
      HomeFooter: () => import('@/layouts/home/Footer'),
      // HomeSettings: () => import('@/layouts/home/Settings'),
      HomeView: () => import('@/layouts/home/View'),
    },
    computed: {
      isMobile: function () {
        // return screen.width > 600
        return true
      },
    },
  }
</script>
